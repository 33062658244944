import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

interface OtherProductsProps {
  products: [
    {
      img: string
      title: string
      description: string
      link: string
    }
  ]
}

const OtherProducts = ({ products }: OtherProductsProps) => {
  const { t } = useTranslation()
  return (
    <section className="uk-section tm-other-product-background">
      <div className="uk-width-1-1">
        <div className="uk-container">
          <div className="uk-width-xlarge uk-text-center uk-margin-auto uk-margin-large-bottom">
            <h3>{t('Not what you looking for')}?</h3>
            <p>{t('Here are our other products')}:</p>
          </div>

          <div className="uk-grid-small uk-child-width-1-3@m" uk-height-match="target: > div > .uk-card" uk-grid="">
            {products.map(({ img, title, description, link }, index: number) => (
              <Link className="tm-disabled-link-decoration" key={index} to={link}>
                <div className="uk-card uk-card-small uk-card-body uk-card-hover uk-flex uk-flex-row">
                  <img src={img} alt="Office Number" uk-img="" width="100" />
                  <div className="uk-margin-left uk-align-center">
                    <h4>{title}</h4>
                    <p>{description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OtherProducts
