import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface BottomCtaProps {
  color: string
}

const BottomCta = (props: BottomCtaProps) => {
  const { t } = useTranslation()

  return (
    <section className="uk-section tm-jascloud-section-background uk-light" style={{ backgroundColor: props.color }}>
      <div className="uk-width-1-1">
        <div className="uk-container">
          <div className="uk-child-width-1-2@m" uk-grid="">
            <div className="uk-text-center uk-text-left@m">
              <h2 className="">{t('Need Help')}?</h2>
              <p className="uk-text-lead">{t('Get demo and consultation')}.</p>
            </div>
            <div className="uk-flex uk-align-center uk-flex-center uk-text-center">
              <a href="/contact" className="uk-button uk-button-primary uk-button-large">
                {t("Let's talk")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomCta
