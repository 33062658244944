import React from 'react'

export type AllStudyCaseType = {
  edges: [
    {
      node: {
        id: string
        languages: [
          {
            id: number
            description: string
            locale: string
            logo: string
            title: string
          }
        ]
      }
    }
  ]
}

export interface StudyCaseSliderProps {
  data: AllStudyCaseType
  language: string
}

export default function StudyCaseSlider({ data, language }: StudyCaseSliderProps) {
  return (
    <div className="uk-section uk-container">
      <div className="uk-container-fluid tm-study-case-container">
        <div className="tm-study-case uk-margin-bottom">
          <h3>Customers</h3>
          <div
            className="uk-position-relative uk-margin-medium-top uk-visible-toggle tm-study-case-slider"
            tabIndex={-1}
            uk-height-match="target: > ul > li > .uk-card"
            uk-slider=""
          >
            <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m uk-grid">
              {data.edges.map(({ node }, index) =>
                node.languages.map(({ locale, logo, description }) => {
                  if (locale == language)
                    return (
                      <li key={index}>
                        <div className="uk-card uk-card-default uk-card-body">
                          <img
                            src={'https://jasnitacms.jasnita.co.idstorage/' + logo}
                            alt=""
                            style={{ maxHeight: '50px', maxWidth: '100px' }}
                          />
                        </div>
                      </li>
                    )
                })
              )}
            </ul>
          </div>
        </div>

        <a
          className="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          // uk-slidenav-previous="false"
          uk-slider-item="previous"
        ></a>
        <a
          className="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          // uk-slidenav-next="false"
          uk-slider-item="next"
        ></a>
      </div>
    </div>
  )
}
