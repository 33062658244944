import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/jascloud.en.yaml'
import contentId from '../../../content/pages/products/jascloud.id.yaml'
import OtherProducts from '../../components/other-products'
import StudyCaseSlider, { AllStudyCaseType } from '../../components/study-case'
import SEO from '../../components/seo'

export const query = graphql`
  query BusinessNetworkQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    voiceStudyCase: allStudyCasesVoice {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    heroFigure: file(relativePath: { eq: "images/business-outsourcing/figure-hero.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    cyberSecurityFigure: file(relativePath: { eq: "images/business-network/figure-cyber-security.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    cyberDefenseFigure: file(relativePath: { eq: "images/business-network/figure-cyber-defense.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    firewallServicesFigure: file(relativePath: { eq: "images/business-network/figure-firewall-services.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    sentrys1000Logo: file(relativePath: { eq: "images/business-network/logo-sentry-s1000.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    sentrys4000Logo: file(relativePath: { eq: "images/business-network/logo-sentry-s4000.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    pointIcon: file(relativePath: { eq: "images/business-network/point.svg" }) {
      id
      publicURL
    }
    autonomousIcon: file(relativePath: { eq: "images/business-network/icon-autonomous.svg" }) {
      id
      publicURL
    }
    invisibleStateIcon: file(relativePath: { eq: "images/business-network/icon-invisible-state-mode.svg" }) {
      id
      publicURL
    }
    ultraFastIcon: file(relativePath: { eq: "images/business-network/icon-ultra-fast.svg" }) {
      id
      publicURL
    }
  }
`

interface BusinessNetworkPagePropsType {
  data: {
    voiceStudyCase: AllStudyCaseType
    pointIcon: PublicUrlType
    heroFigure: IGatsbyImageData
    autonomousIcon: PublicUrlType
    invisibleStateIcon: PublicUrlType
    ultraFastIcon: PublicUrlType
    cyberSecurityFigure: IGatsbyImageData
    cyberDefenseFigure: IGatsbyImageData
    firewallServicesFigure: IGatsbyImageData
    sentrys1000Logo: IGatsbyImageData
    sentrys4000Logo: IGatsbyImageData
  }
}

const BusinessNetworkPage = (props: BusinessNetworkPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const { voiceStudyCase, pointIcon, autonomousIcon, invisibleStateIcon, ultraFastIcon } = props.data

  const heroFigure = getImage(props.data.heroFigure)
  const cyberSecurityFigure = getImage(props.data.cyberSecurityFigure)
  const cyberDefenseFigure = getImage(props.data.cyberDefenseFigure)
  const firewallServicesFigure = getImage(props.data.firewallServicesFigure)
  const sentrys1000Logo = getImage(props.data.sentrys1000Logo)
  const sentrys4000Logo = getImage(props.data.sentrys4000Logo)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-business-network-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    {/* <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} /> */}
                    <h1 className="uk-heading-small">Uncompromised bandwidth for work requiring high-throughput</h1>
                    <p className="uk-text-lead">
                      Jasnita Networks deliver consistently high performing throughput and work with partners to provide
                      you peace of mind with 99.95% service up-time
                    </p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  {/* {heroFigure && <GatsbyImage image={heroFigure} alt="Business Outsourcing" />} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-1-1 uk-text-center uk-margin-large-bottom">
              <h2>Our Services</h2>
            </div>
            <div className="uk-grid uk-child-width-1-3@m uk-flex-center" uk-grid="">
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>End to end network management</h3>
                  <ul>
                    <li>Improved efficiency and support</li>
                    <li>Convenient management with dedicated team</li>
                    <li>Serving to your needs</li>
                    <li>Cost effective no upfront investment</li>
                  </ul>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Dedicated Internet Access</h3>
                  <ul>
                    <li>Enterprise Grade Reliability </li>
                    <li>Full bandwidth always</li>
                    <li>Uninterrupted Fast Speeds</li>
                  </ul>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Static LAN IP</h3>
                  <p>We offer 8, 16, 32, 64 static IPv4 addresses by upon request</p>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>SD-WAN</h3>
                  <p>Leverage a combination of network services:</p>
                  <ul>
                    <li>(MPLS, LTE, Broadband)</li>
                    <li>Securely connect users to applications</li>
                    <li>Centrally control function </li>
                    <li>AI traffic filtering and routing </li>
                    <li>Increased performance</li>
                    <li>Cost effective compared to dedicated link</li>
                  </ul>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Other Managed Services</h3>
                  <ul>
                    <li>Managed firewall - protect your business assets headache free</li>
                    <li>Network filtering - mitigate cyber-attacks and protect your applications and network</li>
                    <li>Managed Wi-Fi Services - provide smooth and seamless Wi-Fi experience in your premises </li>
                    <li>AI traffic filtering and routing </li>
                    <li>
                      Network Management - set up a office LAN, private network, and connect your broadband with voice &
                      collaboration tools for seamless user experience
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-flex-middle" uk-grid="">
              <div className="uk-width-1-3@m">
                <div className="uk-flex uk-flex-center uk-text-center">
                  <div className="uk-width-expand@m">
                    {cyberSecurityFigure && <GatsbyImage image={cyberSecurityFigure} alt="cyber security" />}
                  </div>
                </div>
              </div>
              <div
                className="uk-width-expand@m"
                uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100"
              >
                <h2>Cyber Security</h2>
                <p>Protect your network and digital assets with Jasnita Security Operations Center.</p>
                <p>Provide first and last line of cyber defense with Invisiron. </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-1-2@m uk-text-center uk-margin-auto uk-margin-large-bottom">
              <div className="uk-margin">
                <h2>At the core of Invisiron® is C3X™ which features three mission-critical technologies.</h2>
              </div>
            </div>
            <div>
              <div
                className="uk-child-width-1-3@m uk-text-center"
                uk-grid=""
                uk-height-match="target: > div > .uk-card"
                uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
              >
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="privacy" uk-img={invisibleStateIcon.publicURL} />
                    <h3>Invisible Stealth Mode</h3>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="100 uptime" uk-img={ultraFastIcon.publicURL} />
                    <h3>Ultra Fast In-Line Packet Processing Speed</h3>
                  </div>
                </div>
                <div>
                  <div className="uk-card uk-card-default uk-card-body">
                    <img alt="easy integration" uk-img={autonomousIcon.publicURL} />
                    <h3>Autonomous Threat Prevention</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="tm-left-background">
        <section className="uk-section">
          <div className="uk-with-1-1">
            <div className="uk-container">
              <div className="uk-flex-middle" uk-grid="">
                <div className="uk-width-1-2@m">
                  <div className="uk-flex uk-flex-center uk-text-center">
                    <div className="uk-width-expand@m">
                      {cyberDefenseFigure && <GatsbyImage image={cyberDefenseFigure} alt="cyber defense" />}
                    </div>
                  </div>
                </div>
                <div
                  className="uk-width-1-2@m"
                  uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100"
                >
                  <h2>The Invisiron® Cyber Defence</h2>
                  <p>
                    The Invisiron® Cyber Defence device operates in stealth mode, without any IP addresses or Media
                    Access Control (MAC) addresses. This renders it invisible to cyber criminals when deployed. Hackers
                    attempting to penetrate the network will not be able to detect the presence of an Invisiron® device.
                  </p>
                  <p>Network packets are processed at near line rate without degrading the Internet bandwidth.</p>
                  <p>
                    The Invisiron Cyber Defence Platform operates fully autonomous with no user intervention needed.
                    Threats are detected and mitigated fully automatically and in real-time.
                  </p>
                  <p>
                    The Invisiron® Cyber Defence product line includes the Cyber Defence device, the Invisiron Cyber
                    Threat Intelligence (Invisiron-CTI™) and the Threat Commander Remote Monitoring Software tool.
                    Together, these form a complete Cyber Defence eco-system.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="uk-section">
          <div className="uk-with-1-1">
            <div className="uk-container">
              <div>
                <div
                  className="uk-child-width-1-2@m "
                  uk-grid=""
                  uk-height-match="target: > div > .uk-card"
                  uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
                >
                  <div>
                    <div className="uk-card uk-card-default uk-card-body">
                      {sentrys1000Logo && <GatsbyImage image={sentrys1000Logo} alt="Sentry s1000" />}
                      <p>
                        Designed for Small Enterprises Invisiron® Sentry S-1000 boasts a compact form-factor designed
                        for small networks. The perfect solution for Small Businesses.
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="uk-card uk-card-default uk-card-body">
                      {sentrys4000Logo && <GatsbyImage image={sentrys4000Logo} alt="Sentry s4000" />}
                      <p>
                        Designed for Medium Enterprises Invisiron® Sentry S-4000 is optimised for medium-sized networks.
                        Options for High Availability and Carrier Diversity are available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="uk-section tm-right-background">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div
                className="uk-width-1-2@m"
                uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100"
              >
                <h2>Managed firewall services</h2>
                <p>
                  Secure the network traffic across your organization with the 24x7x365 threat protection of our Managed
                  Firewall services. Choose from a Dedicated Firewall on a physical infrastructure or a Virtual Firewall
                  with unparalleled flexibility.
                </p>
                <p>
                  Our experienced security engineers provide the monitoring and maintenance for your firewall
                  infrastructure, including policy management, upgrades, and any custom configurations your business
                  needs.
                </p>
                <p>
                  Safeguard sensitive data on devices connected to your network & Proactively identify threats and
                  respond accordingly. We provide more than just a security scan. We analyze key risks and find
                  vulnerabilities in the system. We will then put together a tailored solution and manage it for your
                  convenience.{' '}
                </p>
              </div>
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center uk-text-center">
                  <div className="uk-width-expand@m">
                    {firewallServicesFigure && <GatsbyImage image={firewallServicesFigure} alt="Why Jasnita" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StudyCaseSlider data={voiceStudyCase} language={i18n.language} />
      </section>

      <BottomCta color="#C48928" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default BusinessNetworkPage
